.tabbing-box {
  display: flex;
  gap: 20px;
  margin: 20px;
}

.nav-pills-custom .nav-link-custom {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  color: #495057;
  border-bottom: 1px solid #e5e5e5;
}

.nav-pills-custom .nav-link-custom img {
  width: 24px;
  height: 24px;
}

.nav-pills-custom .nav-link-custom.active,
.nav-pills-custom .nav-link-custom:hover {
  background-color: #7258db;
  color: #fff;
}

.tab-content-custom {
  flex-grow: 1;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  width: 100%;
  background-color: #f8f9fa;
}

.subsections {
  margin-left: 20px;
  margin-top: 10px;
}
.headingmain h2 {
  font-size: 30px;
  color: #000;
  font-weight: 700;
  line-height: 40px;
}
.sub-nav-link-custom {
  display: block;
  padding: 5px 15px;
  cursor: pointer;
  color: #495057;
}

.sub-nav-link-customdropdown {
  font-size: 15px !important;
  color: #ffffff !important;
  background-color: #7e65e4 !important;
  display: block;
  padding: 5px 15px;
  cursor: pointer;
}
.sub-nav-right {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 3px;
}
.sub-nav-right .sub-nav-link-custom {
  font-size: 15px !important;
  color: #ffffff !important;
  background-color: #7e65e4 !important;
  display: block;
  padding: 5px 15px;
  cursor: pointer;
}

.sub-content {
  margin-top: 10px;
}
.sub-nav-right .sub-nav-link-custom {
  font-size: 15px !important;
  color: #ffffff !important;
  background-color: #7e65e4 !important;
  display: block;
  padding: 5px 15px;
  cursor: pointer;
}
.sub-nav-link-custom:hover,
.sub-nav-link-custom.active-sub {
  background-color: #e9f7fa;
  color: #007bff;
  border-radius: 5px;
}

.sub-content {
  margin-top: 10px;
}

.sub-nav-right .sub-nav-link-custom2 {
  font-size: 14px !important;
  color: #007bff !important;
  background-color: #e9e9e9 !important;
  display: block;
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 5px;
}
.sub-nav-link-custom2:hover,
.sub-nav-link-custom2.active-sub {
  background-color: #007bff !important;
  color: #ffff !important;
  border-radius: 5px;
}

.sub-content {
  margin-top: 10px;
}

.subsections {
  padding-left: 15px; /* Indentation for subsections */
}

.sub-dropdown {
  padding-left: 15px; /* Indentation for dropdown items */
}

.dropdown-input {
  padding: 10px; /* Padding around the input */
}

.sub-nav-link-custom2 {
  padding: 10px;
  color: #555; /* Darker text for subsections */
}

.sub-nav-link-custom2.active-sub {
  background-color: #007bff; /* Active background */
  color: white; /* White text for active */
}

.sub-nav-link-custom2:hover {
  background-color: #007bff; /* Active background on hover */
  color: white; /* White text for hover */
}

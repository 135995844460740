.application-status-container {
  font-family: Arial, sans-serif;
  color: #8d8556;
  padding: 20px;
  max-width: 625px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  justify-content: end;
}
.header .setting i {
  font-size: 28px !important;
}
.header .navbar-toggler {
  font-size: 28px !important;
}
.back-arrow {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.status-list {
  border-left: 3px solid #8d8556;
  padding-left: 15px;
  margin-bottom: 20px;
}

.status-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.status-item.active {
  color: #000;
}

.status-circle {
  width: 10px;
  height: 10px;
  border: 2px solid #8d8556;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  position: relative;
}

.status-item.active .status-circle {
  background-color: #8d8556;
}

.footer {
  font-size: 14px;
  color: #4a5568;
}

.pageWrapperInside {
  background-color: var(--BodyBackground);
  padding: 0px 20px 0px 0px;

  &.setWidth {
    max-width: 70rem;
    margin: 0 auto;
  }

  :global(.card) {
    background-color: transparent;
    border: none;

    :global(.card-body) {
      padding: 0px;
      margin-bottom: 0px;

      .contentBox {
        border-radius: 8px;
        padding: 20px 20px;
        margin-bottom: 30px;
        border: 1px solid #cdd2de;
        background: #f4f1ff;
      }
    }
  }

  .formField {
    margin-bottom: 10px;

    :global(.react-dropdown) {
      :global(.dropdown__control) {
        height: 40px;
      }
    }

    :global(.form-control) {
      height: 40px;
    }
  }

  .react_select_drop {
    :global(.react-dropdown) {
      :global(.dropdown__control) {
        height: 40px;
      }
    }
  }

  .box_bottom_btnDiv {
    display: flex;
    justify-content: end;
    align-items: center;

    .dropDown_boxs {
      :global(.dropdown) {
        :global(.dropdown-menu) {
          ul {
            padding-left: 0;
            list-style: none;
            margin-bottom: 0px;

            li {
              a {
                display: block;
                width: 100%;
                padding: var(--bs-dropdown-item-padding-y)
                  var(--bs-dropdown-item-padding-x);
                clear: both;
                font-weight: 400;
                color: var(--bs-dropdown-link-color);
                text-align: inherit;
                text-decoration: none;
                white-space: nowrap;
                background-color: transparent;
                border: 0;
                border-radius: var(--bs-dropdown-item-border-radius, 0);

                &:hover {
                  color: var(--bs-dropdown-link-hover-color);
                  background-color: var(--bs-dropdown-link-hover-bg);
                }

                &:focus {
                  color: var(--bs-dropdown-link-hover-color);
                  background-color: var(--bs-dropdown-link-hover-bg);
                }
              }
            }
          }
        }

        :global(.dropdown-toggle) {
          background-color: transparent;
          border: transparent;
          color: #000;
          line-height: 10px;
          font-size: 20px;

          &::after {
            display: none;
          }
        }
      }
    }

    .hover_Box {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &:hover {
        background-color: rgb(240, 248, 255);
      }

      :global(.btn) {
        line-height: 15px;

        :global(svg) {
          font-size: 19px;
        }
      }
    }

    .line_set {
      border-left: 1px solid rgb(218, 220, 224);
      height: 32px;
      margin: 0 16px;
      margin-left: 10px;
      width: 0;
    }

    .togle_flex_box {
      display: flex;
      gap: 11px;
      align-items: center;
      margin-left: 2px;

      span {
        font-size: 15px;
      }
    }
  }

  .sideBtna_showHide {
    position: sticky;
    top: 30px;

    span {
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      display: inline-grid;
      border-radius: 8px;
      background-color: #f1f3f6;
      width: 100%;
      transition: 0.5s;

      &:hover {
        background-color: #7258db;
        color: #fff;

        :global(.btn) {
          color: #fff;

          :global(svg) {
            color: #fff;
          }
        }
      }

      :global(.btn) {
        text-align: center;
        font-size: 10px;
        font-weight: 600;

        :global(svg) {
          font-size: 20px;
          display: block;
          margin: 0 auto 7px;
        }
      }
    }
  }

  :global(.btn) {
    &.btnDisabled {
      background: #7258db;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: none;
      min-width: 150px;
      height: 50px;
      padding: 0px 15px;
      font-size: 18px;
      font-weight: 600;
      line-height: 40px;

      &:hover {
        background: #7258db;
      }
      &:active {
        background: #7258db !important;
      }
    }

    &.editIcon {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
        linear-gradient(0deg, #efeef3, #efeef3),
        linear-gradient(0deg, #efeef3, #efeef3),
        linear-gradient(
          180deg,
          rgba(239, 238, 243, 0.27) 0%,
          rgba(239, 238, 243, 0.29) 100%
        ),
        #dde1eb;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      border: none;
      color: #7f8699 !important;
      padding: 0px;
      font-size: 14px;
      margin-left: 10px;
      float: right;
    }
  }
}

.modalButtonBottom {
  :global(.btn) {
    padding: 8px 50px;
    margin: 0 auto;
  }
}

.labelTitle {
  margin-bottom: 8px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}
label.labelTitle {
  margin-bottom: 8px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}
label.form-label {
  font-size: 18px;
  font-weight: 500;
}

.dummyImage {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7258db;
  font-size: 40px;
  color: #fff;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .editIcon {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background: #7258db;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;

    svg {
      font-size: 16px;
    }
  }

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.buttonBottom {
  display: flex;
  gap: 8px;
  margin-top: 30px !important;

  :global(.btn) {
    padding: 10px 40px;
    font-size: 18px;
  }
}

.tableAction {
  margin-bottom: 15px;

  :global(.btn) {
    background: linear-gradient(0deg, #f3f4f9, #f3f4f9),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(0deg, #efeef3, #efeef3),
      linear-gradient(
        180deg,
        rgba(239, 238, 243, 0.27) 0%,
        rgba(239, 238, 243, 0.29) 100%
      ),
      #dde1eb;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;

    .icon {
      height: 14px;
      margin-right: 8px;
      font-size: 16px;
    }

    &.addBtn {
      color: var(--Main) !important;
    }
    &.upload {
      color: #7f8699 !important;
      position: relative;
      overflow: hidden;

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .dropMenu {
    background-color: #f3f4f9;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    font-size: 14px;
    color: #7f8699 !important;
    width: 220px;
  }
  .totalCount {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;

    span {
      color: #7f8699;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tableAction {
    :global(.btn) {
      font-size: 12px;
      margin-right: 8px;
    }

    .dropMenu {
      width: 180px;
    }
  }
}
:global(.rs-picker-popup.rs-picker-popup-daterange) {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 99999 !important;

  // :global(.rs-picker-daterange-panel) {
  //   max-width: 400px !important;
  //   min-width: 400px !important;
  // }
}


.choose-plan-outer {
  background-color: #fff;
  padding: 30px 30px;
  box-shadow: 0px 0px 10px 0px #ddd;
  border-radius: 20px;
  height: 540px;

  .spinnerCenter{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 170px 0px;
  }

      .card-plen {
        background-color: #f8f7ff;
        border-radius: 15px;
        padding: 30px 30px;
        border: 0px;
        text-align: center;
        margin: 20px 0px 0px;
        min-height: 360px;
        max-height: 600px;
        position: relative;

        &.bg-primary{

          h4, h3{
            color: #fff;
          }

          ul{

            li{
              color: #fff;

              svg{
                fill: #fff;
              }
            }
          }

          button{
            background-color: #fff;
            color: #7258db;
            border: 1px solid #fff;
          }
        }

        h4 {
          font-size: 22px;
          font-weight: 600;
          color: #333;
          margin: 10px 0px 20px;
        }
        h3 {
          font-size: 28px;
          font-weight: 800;
        }
        span {
          font-weight: 400;
          font-size: 16px; 
        }

        button {
          background-color: #7258db;
          color: #fff;
          border: 1px solid #7258db;
          padding: 10px 40px;
          border-radius: 6px;
          display: inline-block;
          width: 210px;
          margin: 10px auto 10px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translate(-50%, 0%);
        }

        ul {
          padding: 0px;
          margin: 20px 0px;

          li {
            font-size: 16px;
            color: #333;
            padding: 0 0px;
            display: flex;
            justify-content: left;
            align-items: center;
            gap: 5px;
            line-height: 33px;
            width: 68%;
            position: relative;
            margin: 0 auto;
            padding-left: 25px;
            text-align: left;
          }
          svg {
            fill: #7258db;
            position: absolute;
            left: 0px;
            top: 10px;
        }
      }
      

      }
}

.hourseWork{
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 0px;
  flex-wrap: wrap;

  button{
    padding: 10px 6px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px !important;
    min-width: 49%;
  }
}

.modalButtonBottom1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .btn {
    padding: 12px 40px;
    width: 44%;
    margin-top: 10px;
}
}

.priceValueBox{

  input{
    height: 50px;
    border: 1px solid #b6b6b6;
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }

  span{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0%, -50%);
  }
}
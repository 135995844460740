/*=====  cart=====*/

.card-body {
    padding: 0px 10px !important;
}

.table > :not(caption) > * > * {
    padding: 0px 10px !important;
}

.product-cart {
	.checkout-table {
		.table {
			margin-bottom: 0;
			thead {
				tr {
					th {
						padding: 9px 15px;
						border: 0;
						border-bottom: 1px solid  var(--border-color);
						font-size: 14px;
						font-weight: bold;
						&:before,&:after{
							@media (max-width: 991px) {
								display: none;
							}
						}
					}
					th.product {
						min-width: 240px;
						@media (max-width: 991px) {
							min-width: 100%;
						}
					}
					th.size {
						min-width: 80px;
						width: 80px;
					}
					th.quantity {
						width: 100px;
					}
					th.price {
						min-width: 80px;
						width: 80px;
					}
					th.action {
						min-width: 100px;
						width: 100px;
					}
				}
			}
			tbody {
				tr {
					td {
						border: 0;
						border-bottom: 1px solid  var(--border-color);
						padding: 15px;
						vertical-align: middle;
					}
				}
			}
		}
		.product-cart {
			.product-content {
				padding-left: 8px;
				.title {
					a {
						font-weight: 500;
						font-size: 16px;
						line-height: 22px;
						transition: all 0.3s linear;
					}
				}
				span {
					font-weight: 300;
					font-size: 13px;
					line-height: 16px;
					margin-top: 4px;
				}
			}
		}
		.product-size {
			position: relative;
			.nice-select {
				width: 70px;
				border: 1px solid  var(--border-color);
				height: 35px;
				line-height: 33px;
				background-color: transparent;
				padding: 0 10px;
				float: none;
				border-radius: 4px;
				&::after {
					width: 9px;
					height: 9px;
					right: 10px;
					border-color: #000;
				}
				.current {
					font-size: 16px;
					color: #000;
					line-height: 33px;
					font-weight: 500;
				}
				.list {
					width: 100%;
				}
			}
		}
		.product-quantity {
			border: 1px solid  var(--border-color);
			overflow: hidden;
			border-radius: 4px;
			display: inline-block;
			input {
				width:100px;
				height: 35px;
				border: 0;
				padding: 0 5px;
				text-align: center;
				font-weight: 700;
				background-color: transparent;
				color:var(--text-color);
				@media (max-width: 767px) {
					width:80px;
				}
			}
		}
		.price {
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 0px;
		}
		.action {
			padding: 0px;
			margin-bottom: 0px;
			li {
				display: inline-block;
				a {
					font-size: 24px;
					width: 32px;
					height: 32px;
					line-height: 32px;
					text-align: center;
				}
				a.favorite {
					color: #7D7D7D;
				}
				a.delete {
					color: #FF4B22;
				}
			}
		}
	}
	.checkout-coupon-total {
		.checkout-coupon {
			width: 50%;
			padding: 15px 0px;
			span {
				font-size: 13px;
				line-height: 16px;
			}
		}
		.checkout-total {
			width: 50%;
			border-left: 1px solid var(--border-color);
			.single-total {
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: flex-end;
				padding: 0 8px;
				.value {
					font-weight: 300;
					font-size: 14px;
					line-height: 32px;
					margin-right: 16px;
				}
				.price {
					font-weight: 500;
					font-size: 16px;
					line-height: 32px;
					width: 85px;
				}
			}
			.single-total.total-payable {
				border-top: 1px solid  var(--border-color);
				.value {
					font-weight: 500;
				}
			}
		}
	}
	.checkout-coupon-total.checkout-coupon-total-2 {
		.checkout-coupon {
			width: 70%;
		}
		.checkout-total {
			width: 30%;
			.single-total {
				justify-content: space-between;
				.price {
					width: auto;
				}
			}
		}
	}
	.checkout-btn {
		padding: 0px 15px 15px;
		border-top: 1px solid  var(--border-color);
		.single-btn{
			margin-bottom: 5px;
		}
	}
}
@media (max-width: 767px) {
	.product-cart {
		.checkout-coupon-total {
			.checkout-coupon {
				width: 100%;
				padding: 15px 0px;
			}
			.checkout-total {
				width: 100%;
				border-left: 0;
				border-top: 1px solid  var(--border-color);
			}
		}
		.checkout-coupon-total.checkout-coupon-total-2 {
			.checkout-coupon {
				width: 100%;
				padding: 15px 0px;
			}
			.checkout-total {
				width: 100%;
				padding: 15px 0px;
			}
		}
		.checkout-btn{
			padding: 15px 0px;
		}
	}
}

/*=====  checkout =====*/
.checkout-steps{
	ul {
		list-style: none;
		padding: 0px;
		li {
			border-radius: 8px;
			+ {
				li {
					margin-top: 12px;
				}
			}
		}
	}
	.title {
		font-size: 16px;
		font-weight: 500;
		line-height: 40px;
		position: relative;
		cursor: pointer;
		&::before {
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			content: "\ea99";
    		font-family: 'IcoFont' !important;
			font-size: 24px;
			color: var(--primary-color);
			transition: all 0.3s linear;
		}
	}
	.checkout-steps-form-content {
		padding-top: 0;
		border-top: 1px solid var(--border-color);
		.steps-form-btn {
			padding-top: 10px;
		}
		.checkout-payment-option {
			margin-top: 16px;
			.payment-option-wrapper {
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				flex-wrap: wrap;
				margin-left: -4px;
				margin-right: -4px;
				.single-payment-option {
					width: 25%;
					margin-top: 16px;
					padding: 0 4px;
					input[type="radio"] {
						display: none;
						+ {
							label {
								border: 1px solid var(--border-color);
							}
						}
						&:checked {
							+ {
								label {
									border: 1px solid var(--primary-color);
								}
							}
						}
					}
					label {
						text-align: center;
						padding: 12px;
						border-radius: 5px;
						-webkit-border-radius: 5px;
						-moz-border-radius: 5px;
						-ms-border-radius: 5px;
						-o-border-radius: 5px;
						margin-bottom: 0;
						width: 100%;
						transition: all 0.3s linear;
						cursor: pointer;
						.s-info {
							font-size: 14px;
							font-weight: 300;
							margin-top: 12px;
							display: block;
							margin-bottom: 15px;
						}
						.price {
							font-weight: 500;
							font-size: 16px;
							line-height: 22px;
							margin-top: 4px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.checkout-steps{
		.checkout-steps-form-content {
			.checkout-payment-option {
				.payment-option-wrapper {
					.single-payment-option {
						width: 50%;
						label {
							padding: 8px;
							p {
								font-size: 12px;
							}
							.price {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.checkout-steps{
		.checkout-steps-form-content {
			.checkout-payment-option {
				.payment-option-wrapper {
					.single-payment-option {
						width: 100%;
					}
				}
			}
		}
	}
	.fc .fc-toolbar-title{
		font-size: 18px;
	}
}

.checkout-sidebar-price-table {
	.title {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #000;
		padding-bottom: 12px;
	}
	.sub-total-price {
		border-top: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		padding: 8px 0;
		.total-price {
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			.value {
				line-height: 32px;
				font-size: 14px;
			}
			.price {
				line-height: 32px;
				font-size: 16px;
			}
		}
		.shipping {
			.value {
				color: #FF4B22;
			}
			.price {
				color: #FF4B22;
			}
		}
		.discount {
			.value {
				color: #7CB637;
			}
			.price {
				color: #7CB637;
			}
		}
	}
	.total-payable {
		padding: 8px 0;
		.payable-price {
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			.value {
				line-height: 32px;
				font-size: 14px;
				font-weight: 500;
			}
			.price {
				line-height: 32px;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
	.price-table-btn {
		margin-top: 16px;
	}
}



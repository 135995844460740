

// Chat app page scss
.card-chat,
.card-chat-body{
    height: 90vh;
}

// left sidebar chat list, groups and contact
.chatlist-toggle{
    color: var(--primary-color);
}
.card-chat{
    min-width: 380px;

    @media only screen and (max-width: $break-medium - 1px) {
        @include transition(ease .1s);
        position: fixed;
        left: -600px;
        z-index: 9;

        &.open{
            left: 0;
        }
    }
    @media only screen and (max-width: $break-small - 1px) {
        &.open{
            min-width: 300px;
            width: 300px;
        }
    }
    @media only screen and (max-width: 360px) {
        &.open{
            min-width: 260px;
            width: 260px;
        }
    }

    .tab-content{
        @include overflow(scroll);
        overflow-y: auto;
        height: calc(100vh - 150px);
    }

    .list-group{
        .list-group-item{
            &.open,
            &:hover{
                background-color: var(--body-color);
            }
            a{
                color: var(--color-500);
            }
        }
        .msg-time{
            font-size: 11px;
            font-weight: 600;
        }
    }
    .nav-link{
        @media only screen and (max-width: $break-small - 1px) {
            padding: 5px ;
        }
    }
}

.chatDesign{

    .topsearchBar{
        background-color: #d7cfff !important;
    }

    .input-group{

        input{
            padding: 14px 20px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .tab-content{

        ul{

            li{
                padding: 30px 30px !important;

                img{
                    border-radius: 50px !important;
                }
            }
        }
    }
}

.chatBoxRoom{
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    img{
        width: 200px;
        height: auto;
        display: flex;
        margin: 0 auto;
    }

    h3{
        font-size: 28px;
        font-weight: 600;
        justify-content: center;
        display: flex;
        margin: 15px auto 0px;
        width: 100%;
    }
}

.card-chat-body {
    background-color: #ffffff !important;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px -2px #dededeb3;

    .text-light{
        .user-info{

            .text-muted{
                color: #fff !important;
            }
        }
    }

    .leftColor {
        background-color: #ece8ff;

        .user-info{

            .text-muted{
                color: #040404;
                margin-top: 12px;
                display: flex;
                justify-content: flex-end;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }

    .text-muted{
        font-size: 17px;
        font-weight: 600;
        color: #000 !important;
    }

    .chatButton{
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 12px 20px;
        background: #ffffff;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        box-shadow: 0px 0px 12px -2px #dededeb3;

        .form-control{
            height: 50px;
            padding: 12px 20px;
            border-radius: 6px;
        }

        button{
            padding: 10px 30px;
            height: 50px;
            border: 1px solid #7258db;
            background: #7258db;
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            border-radius: 6px;
        }
    }
}

// 
.card-chat-body{

    @media only screen and (max-width: $break-small - 1px) {
        height: calc(100vh - 60px);
    }

    .max-width-70{
        max-width: 70%;

        @media only screen and (max-width: $break-small) {
            max-width: 100%;
        }
    }

    .chat-history{
        @include overflow(scroll);
        height: 100vh;
        overflow-y: auto;
    }

    .text-right{
        .message{
            color: $white;
        }    
    }
    .message{
        color: var(--color-600);
    }

    &.card{
        background-color: var(--body-color);
    }
}

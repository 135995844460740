.basic-info-container {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.basic-info-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.basic-info-row {
  margin-bottom: 15px;
}

.basic-info-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.basic-info-value {
  display: flex;
  align-items: center;
}

.edit-field {
  display: flex;
  align-items: center;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
}

.form-control {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
}

.update-button-container {
  margin-top: 20px;
}

.update-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

// card 
.card{
    border-color: var(--border-color);
    background-color: var(--card-color);
    &.card-callout{
        border-left-width: .4rem;
        border-left-color: var(--primary-color);
    }
    .card-header{
        background-color: transparent;
        border-bottom: none;
    }
    .card-footer{
        border-color: var(--border-color);
    }

}

.dispalePage{
    position: relative;

    &:before {
        background: #e9ecef;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: .4;
        z-index: 9999;
    }
}

.row-deck {
    >.col,
    >[class*='col-']{
        display: flex;
        align-items: stretch;
        .card{
            flex: 1 1 auto;
        }
    }
}
h1 {
  font-weight: bold;
  color: #003366;
}

.card {
  border: none;
  background-color: #f8f9fa;
}

.card-body {
  border-left: 4px solid #003366;
  padding: 20px;
}

.question {
  font-size: 16px;
  font-weight: bold;
  color: #003366;
  margin-bottom: 15px;
}

.answer {
  background-color: #ffffff;
  color: #333;
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;
  margin-bottom: 15px;
}

.answer .bi-mic {
  cursor: pointer;
  font-size: 1.2em;
}


.dropzone {
    position: relative;
    border-radius: 0.95rem;
    border: 1px solid var(--border-color);
    border-image: none;
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
    display: flex;
    justify-content: center;
    height: 250px;
    margin-bottom: 20px;
}
.dropzone input[type='file']{
    background-color: rgb(134, 127, 84);
    opacity: 0;
}
.dropzone input {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.dropzone .dz-message i {
    font-size: 75px;
    color: rgba(0, 0, 0, 0.2);
}
.dz-message .fa {
    // display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 100%;
}

.email-template {
  padding: 20px 0px;
}
.email-template .container {
  width: 100%;
  margin: 0 auto;
}
.email-template .container .box .image-center {
  text-align: center;
}
.email-template .container .box {
  padding: 10px 0px 0px;
  border-radius: 15px;
  border: 1px solid #ececec;
}
.email-template .container .box img {
  width: 30%;
}
.email-template .container .box .text {
  padding: 50px 100px;
}
.email-template .container .box .text .date {
  width: 100%;
  text-align: right;
}
.email-template .container .box .text ul {
  list-style: none;
  padding-left: 0px;
  line-height: 30px;
}
.email-template .container .box .text ul li {
  position: relative;
  padding-left: 42px;
}
.email-template .container .box .text ul li span {
  position: absolute;
  left: 0px;
  top: 0px;
}
.email-template .container .box .text .signImg {
  padding: 40px 0px;
}
.email-template .container .box .text .signImg img {
  width: 150px;
}
.email-template .container .box .text a {
  color: #0b284d;
  font-weight: 600;
  text-decoration: none;
}
.email-template .container .box .text p {
  margin-bottom: 8px;
  margin-top: 0px;
}
.signaturePlat {
  background-color: #ebebeb;
  margin: 10px 0px;
  border: 1px solid #ebebeb;
  padding: 10px 0px;
  border-radius: 10px;
}
.backBtn {
  padding: 5px 0px !important;
  display: inline-flex !important;
  align-items: center;
  gap: 4px;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 10px;
}
.backBtn:hover {
  text-decoration: underline !important;
}

@media (max-width: 785px) {
  .email-template .container .box .text {
    padding: 10px 10px;
  }
  .email-template .container .box .text p {
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 10px;
  }
  .email-template .container .box .text b {
    font-size: 10px;
  }
  .email-template .container .box .text ul li {
    padding-left: 22px;
    font-size: 10px;
  }
  .email-template .container .box .text ul {
    line-height: 20px;
  }
  /* .email-template .container .box .signaturePlat canvas.sigCanvas {
    width: 100%;
    height: 10vh;
  } */
}

.timeline-item {
	border-left: 2px solid var(--border-color);
	display: flex;
	position: relative;
	padding: 1em 2em 2em;
	
	&:hover{
		&::after{
			transform: scale(3.5);
		}
	}

	&::after {
		@include transition(ease .1s);
        border: 2px solid var(--border-color);
		background: var(--color-900);
        display: block;
        position: absolute;
        content: '';
        width: 8px;
		height: 8px;
		top: 1.3em;
		left: -5px;
		border-radius: 30px;
	}
	&.ti-primary:after{
		background: var(--primary-color);
	}
	&.ti-info:after{
		background: #4FB8C9;
	}
	&.ti-success:after{
		background: #7EB93F;
	}
	&.ti-warning:after{
		background: #f8b500;
	}
	&.ti-danger:after{
		background: #FF1843;
	}
	
}